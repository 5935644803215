import { Component, Vue, Prop } from 'vue-property-decorator';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { Report as ReportModel } from '@/models/Report';
import { Organization } from '@/models/Organization';

@Component<RequestDialog>({})
export default class RequestDialog extends Vue {
  public $pageTitle = 'Aanvragen';

  protected actionDisabled = false;

  @Prop({ default: '' })
  protected title!: string;

  @Prop()
  protected report?: ReportModel;

  protected organizations: Organization[] = [];

  protected selectedOrganization = '';

  protected organizationSearch = '';

  protected isLoading = false;

  protected comment = '';

  public mounted() {
    this.initialize();
  }

  protected initialize() {
    this.getOrganizations();
  }

  protected getOrganizations() {
    new Organization()
      .getAllExperts()
      .then((organizations: Organization[]) => {
        this.organizations = organizations;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected close() {
    this.selectedOrganization = '';
    this.organizations = [];
    this.comment = '';
    this.isLoading = false;
    this.$emit('closeDialog', this.report);
    this.$emit('input', false);
  }

  protected reportRequest() {
    if (! this.report || ! this.selectedOrganization.length) {
      return;
    }

    this.isLoading = true;

    const payload: any = {};
    payload.status = 'expert_requested';
    payload.organization = this.selectedOrganization;

    if (this.comment.length) {
      payload.status_comment = this.comment;
    }

    this.report
      .update(payload)
      .then(() => {
        this.isLoading = false;
        this.close();
        this.$router.push(`/reports/${this.report ? this.report.uuid : ''}`);
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        this.isLoading = false;
      });
  }
}
