var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        { staticClass: "approval-dialog" },
        [
          _vm.isLoading
            ? _c("LoaderCard", {
                attrs: { flat: "", type: "spinner--center", minHeight: "300px" }
              })
            : [
                _c(
                  "v-card-text",
                  [
                    _c(
                      "v-layout",
                      { attrs: { row: "", wrap: "" } },
                      [
                        _c("v-flex", { attrs: { xs12: "" } }, [
                          _c(
                            "div",
                            { staticClass: "icon" },
                            [_c("v-icon", [_vm._v("warning")])],
                            1
                          )
                        ]),
                        _c("v-flex", [_c("h2", [_vm._v(_vm._s(_vm.title))])])
                      ],
                      1
                    ),
                    _c(
                      "v-layout",
                      {
                        staticClass: "approvalDialogContainer",
                        attrs: { row: "", wrap: "" }
                      },
                      [
                        _c(
                          "v-flex",
                          { attrs: { xs12: "" } },
                          [
                            _c("label", { staticClass: "selectLabel" }, [
                              _vm._v("Selecteer een expertisebureau")
                            ]),
                            _c("v-select", {
                              attrs: {
                                items: _vm.organizations,
                                label: "Expertisebureau",
                                "item-text": "name",
                                "item-value": "id"
                              },
                              model: {
                                value: _vm.selectedOrganization,
                                callback: function($$v) {
                                  _vm.selectedOrganization = $$v
                                },
                                expression: "selectedOrganization"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-flex",
                          { attrs: { xs12: "" } },
                          [
                            _c("v-textarea", {
                              attrs: { name: "comment", label: "Opmerkingen" },
                              model: {
                                value: _vm.comment,
                                callback: function($$v) {
                                  _vm.comment = $$v
                                },
                                expression: "comment"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-card-actions",
                  { staticClass: "buttonContainer" },
                  [
                    _c(
                      "v-layout",
                      { attrs: { "justify-space-between": "", row: "" } },
                      [
                        _c(
                          "MiButton",
                          {
                            attrs: { color: "text-light", outline: "true" },
                            nativeOn: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.close($event)
                              }
                            }
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$tc("general.cancel")) + " "
                            )
                          ]
                        ),
                        _c(
                          "MiButton",
                          {
                            staticClass: "float--right",
                            attrs: {
                              disabled: !_vm.selectedOrganization.length,
                              color: "success"
                            },
                            nativeOn: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.reportRequest($event)
                              }
                            }
                          },
                          [_vm._v(" Uitvragen ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }